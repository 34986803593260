import React from 'react';
import { Card } from 'react-bootstrap';
import '../styles/global.scss';
import '../styles/card.scss';

const Page = ({ title, subtitle, path, body, tech, source, image }) => {
  return (
    <Card
      data-sal="slide-up"
      data-sal-duration="900"
      data-sal-delay="0"
      data-sal-easing="ease"
      style={{ position: 'relative', width: '100%' }}
    >
      <Card.Img src={image.src} alt={title} style={{ display: 'block', width: '100%' }} />
      <Card.Body
        className="overlay"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
      >
        <div className="text">
          {source === '' ? (
            <Card.Title>{title}</Card.Title>
          ) : (
            <Card.Title>
              <a href={source} target="_blank" rel="noreferrer">
                {title}
              </a>
            </Card.Title>
          )}
          <Card.Subtitle> {subtitle} </Card.Subtitle>
        </div>
      </Card.Body>
    </Card>
  );
};
export default Page;
